import { globalEnvironment } from "./globalEnvironment";
import { labelsCatasia } from "../app/resource/labels";

const baseURL = "https://qa.catasia.co";

export const environment = {
  environment: "demo",
  production: false,
  name: "demo",
  host: globalEnvironment,
  API_URL: `${baseURL}/api/private/`,
  API_LOGIN_URL: `${baseURL}/api/security/oauth/`,
  labels: labelsCatasia["version2.0"],

  //GUI
  theme: "blue",
  // logoLogin: "assets/layout/images/gentemovil/logo.png",
  logoLogin: "assets/layout/images/gentemovil/logo_demo.png",
  loginText: null,
  // logoTopBar: "assets/layout/images/gentemovil/logo.png",
  logoTopBar: null,
  textTopBar: null,
  logofooterEsri: "assets/layout/images/LogosEsri/Col blanco vertical.png",
  logofooterEsriHeight: "70px",
  logofooterCatasIA: "assets/layout/images/gentemovil/Ver_GenteMovil_banco.png",
  logofooterCatasIAHeight: "70px",
  logoLoginHeight: "200px",
  desarrollo: true
};
